<template>
  <div class="feed-post" v-if="post">
    <div class="post-group">
      <CreatorPostCard v-for="(post, index) in post.group" :post="post" :index="index" :key="post._id" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["post", "index"],
  components: {
    CreatorPostCard: () => import("../creator/CreatorPostCard.vue"),
  },
  data: function() {
    return {
      messageLength: 130,
      moretxt: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.post-group {
  flex: 1;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  .creator-post-header,
  .creator-post-message,
  .subscribe-message-p,
  .creator-post-footer,
  .post-unlock-wrap .post-unlock-price {
    display: none;
  }
  .file,
  .creator-post,
  .creator-post-media {
    img,
    video {
      display: none;
    }
  }
  .feed-post {
    display: flex;
    flex: 0.33;
    position: relative;
    overflow: hidden;
    height: 226px;
  }
  .creator-post,
  .creator-post-media,
  .postMediaSlider,
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide,
  .creator-post-media-wrap {
    height: 100%;
    background-color: transparent;
    border-radius: 0;
  }
  .slick-slide > * {
    height: 100%;
  }
  .slick-slider {
    * {
      opacity: 0;
    }
    .creator-post-media-wrap * {
      display: none;
    }
  }
  .creator-post-media {
    background-image: var(--cover);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
